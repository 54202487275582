import axios from 'axios';

const PersonaService = {
    getPersonaByDNI: dni  => { 
        return axios.get('/api/polideportivo/persona/' + dni).then(resp => resp.data)
    },
    getLocalidades: () => {
        return axios.get('/api/polideportivo/localidades').then(resp => resp.data)
    }
}
export default PersonaService