import React from "react";
import Authentication from '../../services/authentication-service'
import { UserContext } from '../../contexts/userContext'

function Login(props) {

  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const { setUser } = React.useContext(UserContext)

  const submit = e => {
    e.preventDefault()
    Authentication.login(username, password).then(resp => {
      Authentication.getSession().then(user => {
        setUser(user)
      })
    }).catch(error => {
      console.log(error)
    })
  }

  return (
    <div className="auth-wrapper">
      <div className="auth-inner">
        <form autoComplete="off" onSubmit={submit}>
          <h3>Ingresar</h3>

          <div className="form-group">
            <label>Usario</label>
            <input type="text" className="form-control" placeholder="Ingrese su usuario"
              value={username}
              onChange={event => setUsername(event.target.value)} />
          </div>

          <div className="form-group">
            <label>Password</label>
            <input type="password" className="form-control" placeholder="Ingrese su password"
              value={password}
              onChange={event => setPassword(event.target.value)} />
          </div>

          <button type="submit" className="btn btn-primary btn-block">Ingresar</button>
        </form>
      </div>
    </div>
  );
}

export default Login