export const NoData = {
  afterDraw: function (chart) {
    if (chart.data.datasets.length === 0 || 
      chart.data.datasets[0].data.findIndex(it => it > 0) === -1) {
      // No data is present
      var ctx = chart.ctx;
      var width = chart.width;
      var height = chart.height
      chart.clear();

      ctx.save();
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText('No hay información para mostrar, ajuste los parámetros de búsqueda', width / 2, height / 2);
      ctx.restore();
    }
  }
};