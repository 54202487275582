import axios from 'axios';
import UtilsService from './utils-service'

const EstadisticaService = {

    getEstadisticasCantidad: (startDate, endDate) => {
        return axios.get('/api/polideportivo/estadisticas',
            {
                params: {
                    startDate: UtilsService.formatDateToServer(startDate),
                    endDate: UtilsService.formatDateToServer(endDate)
                }
            }
        ).then(resp => resp.data)
    }

}

export default EstadisticaService