import axios from 'axios';

const ExentoService = {
    getExentos: () => {
        return axios.get('/api/polideportivo/exento').then(resp => resp.data)
    },
    createExento: exento => {
        return axios.post('/api/polideportivo/exento', exento).then(resp => resp.data)
    },
    getExento: id => {
        return axios.get('/api/polideportivo/exento/' + id).then(resp => resp.data)
    },
    updateExento: exento => {
        return axios.put('/api/polideportivo/exento', exento).then(resp => resp.data)
    },
    anularExento: id => {
        return axios.post(`/api/polideportivo/exento/${id}/anular`).then(resp => resp.data)
    }

}

export default ExentoService