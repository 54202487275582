import React from "react";

import '../../../../App.css'

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import { Link, useParams, useHistory } from "react-router-dom";
import ExentoService from "../../../../services/exento-service";
import { UIContext } from "../../../../contexts/uiContext";
import LocalidadSelect from "../../../shared/localidad-select";

const emptyForm = {
    dni: '',
    nombre: '',
    apellido: '',
    estado: 'ACTIVO'
}
const defaultLocalidad = { value: 'Seguí (Entre Ríos)', label: 'Seguí (Entre Ríos)' }


function ExentoNew() {

    const history = useHistory()
    const [form, setForm] = React.useState(emptyForm)
    const [showModal, setShowModal] = React.useState(false)
    const { showMessage } = React.useContext(UIContext)
    const { id } = useParams()
    const [validated, setValidated] = React.useState(false)
    const [localidad, setLocalidad] = React.useState(defaultLocalidad)


    const formRef = React.useRef(null);


    React.useEffect(() => {
        if (id) {
            ExentoService.getExento(id).then(exento => {
                setForm(exento)
            });
        }
    }, [])

    const handleFormValue = (name, value) => {
        const newForm = {
            ...form,
            [name]: value
        }
        setForm(newForm)
    }

    const handleSuccess = message => {
        setShowModal(false)
        showMessage(message, 'success')
        history.push('/exentos')
    }

    const submit = () => {
        const body = Object.assign({}, form)
        body.localidad = localidad.value
        if (id) {
            ExentoService.updateExento(body).then(exento => {
                handleSuccess('Exento actualizados')
            })
        } else {
            ExentoService.createExento(body).then((data) => {
                handleSuccess('Nuevo exento creado')
            })
        }
    }

    function confirmar() {
        const form = formRef.current
        if (form.checkValidity()) {
            setShowModal(true)
        }
        setValidated(true)
    }


    return (
        <div>
            <Container className="central-div" >
                <Card>
                    <h1>Agregar o modificar exentos</h1>
                    <Card.Body>
                        <Form noValidate validated={validated}
                            className="central-div"
                            autoComplete="off"
                            style={{ minHeight: '615px' }}
                            ref={formRef}
                        >
                            <Row>
                                <Col>
                                    <Form.Group controlId="DNI">
                                        <Form.Label>DNI</Form.Label>
                                        <Form.Control
                                            type="text"
                                            required
                                            name="DNI"
                                            value={form.dni}
                                            onChange={e => handleFormValue('dni', e.target.value)} />
                                    </Form.Group>
                                    <Form.Group controlId="Nombre" required>
                                        <Form.Label>Nombre</Form.Label>
                                        <Form.Control type="text"
                                            name="nombre"
                                            required
                                            value={form.nombre}
                                            onChange={e => handleFormValue('nombre', e.target.value)} />
                                    </Form.Group>
                                    <Form.Group controlId="apellido">
                                        <Form.Label>Apellido</Form.Label>
                                        <Form.Control type="text" required name="apellido" value={form.apellido}
                                            onChange={e => handleFormValue('apellido', e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group controlId="localidad">
                                        <Form.Label>Localidad</Form.Label>
                                        <LocalidadSelect value={localidad} onSelect={(localidad) => setLocalidad(localidad)} />
                                    </Form.Group>
                                    <Form.Group controlId="apellido">
                                        <Form.Label>Teléfono</Form.Label>
                                        <Form.Control type="text" required name="telefono" value={form.telefono}
                                            onChange={e => handleFormValue('telefono', e.target.value)} />
                                    </Form.Group>
                                    <Form.Group controlId="apellido">
                                        <Form.Label>Dirección</Form.Label>
                                        <Form.Control type="text" required name="direccion" value={form.direccion}
                                            onChange={e => handleFormValue('direccion', e.target.value)} />
                                    </Form.Group>
                                    <Form.Group controlId="estado">
                                        <Form.Label>Estado de la exención</Form.Label>
                                        <Form.Control type="text" required
                                            disabled
                                            name="estado"
                                            value={form.estado}
                                            onChange={e => handleFormValue('estado', e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                        <Row className="row-mb-5">
                            <Col>
                                <div className="central-div">
                                    <Link to={"/exentos"}>
                                        <Button size="lg" variant="danger" className="button-mr-5">
                                            Cancelar
                                        </Button>
                                    </Link>
                                    <Button
                                        size="lg"
                                        type="submit"
                                        onClick={() => confirmar()} >
                                        Confirmar
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Container>

            <Modal show={showModal} onHide={() => setShowModal(false)} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar</Modal.Title>
                </Modal.Header>
                <Modal.Body>¿Seguro que quieres agregar exento?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={submit}>
                        Guardar exento
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ExentoNew