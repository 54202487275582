import React from "react";
import Select from 'react-select';
import PersonaService from "../../services/persona-service";
import { UIContext } from '../../contexts/uiContext'


function LocalidadSelect(props) {

    const [localidades, setLocalidades] = React.useState([])
    const { showMessage } = React.useContext(UIContext)


    React.useEffect(() => {
        PersonaService.getLocalidades()
            .then(data => setLocalidades(data.map(localidad => ({ value: localidad, label: localidad }))))
            .catch(() => showMessage('Ocurrió u error al recuperar las localidades', 'error'))

    }, [])

    return (

        <Select options={localidades} value={props.value}
            required
            onChange={e => props.onSelect(e)} />


    )
}

export default LocalidadSelect