const getStorage = function (type) {
  if (type === 0 /* SESSION */) {
    return window.sessionStorage;
  }
  return window.localStorage;
};

const setItem = function (type) {
  return function (key, value) {
    getStorage(type).setItem(key, JSON.stringify(value));
  };
};

const getItem = function (type) {
  return function (key, defaultVal) {
    const val = getStorage(type).getItem(key);
    if (!val || val === 'undefined')
      return defaultVal;
    try {
      return JSON.parse(val);
    } catch (e) {
      return val;
    }
  };
};

const removeItem = function (type) {
  return function (key) {
    getStorage(type).removeItem(key);
  };
};

const Storage = {
  session: {
    get: getItem(0 /* SESSION */),
    set: setItem(0 /* SESSION */),
    remove: removeItem(0 /* SESSION */)
  },
  local: {
    get: getItem(1 /* LOCAL */),
    set: setItem(1 /* LOCAL */),
    remove: removeItem(1 /* LOCAL */)
  }
}; 

export default Storage