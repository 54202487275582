import React from 'react'

import EntradasService from '../../../../services/entradas-service';
import { UIContext } from '../../../../contexts/uiContext'

import '../../../../App.css'

import Modal from 'react-bootstrap/Modal';
import { ImCancelCircle } from "react-icons/im";
import Button from "react-bootstrap/Button";
import { FiRefreshCcw } from "react-icons/fi";
import { ADMIN_ROLE } from '../../../../config/constants'
import { UserContext } from '../../../../contexts/userContext';
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

import { BsFillPrinterFill } from "react-icons/bs";

function EntradasList() {

  const { hasRole } = React.useContext(UserContext)

  const [entradas, setEntradas] = React.useState([])

  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const [usuario, setUsuario] = React.useState('');
  const [showModal, setShowModal] = React.useState(false);
  const [entradaSeleccionada, setEntradaSeleccionada] = React.useState({});


  const { showMessage, printDataUrl, setLoading } = React.useContext(UIContext)

  React.useEffect(() => {
    buscarEntradas()
  }, [startDate, endDate])

  const reimprimirEntrada = id => {
    setLoading(true)
    EntradasService.getReporteEntrada(id).then(dataUrl => {
      printDataUrl(dataUrl);
    }).catch(error => {
      showMessage(error, 'error');
    }).finally(() => setLoading(false))
  }

  const anularEntrada = id => {
    setLoading(true)
    EntradasService.anularEntrada(id).then(data => {
      reimprimirEntrada(id)
      setShowModal(false)
      buscarEntradas()
    }).catch(error => {
      showMessage(error, 'error');
    }).finally(() => setLoading(false))
  }

  function checkKeyPress(event) {
    if (event.key === 'Enter') {
      buscarEntradas()
    }
  }

  function buscarEntradas() {
    setLoading(true);
    EntradasService.getEntradas(usuario, startDate, endDate)
      .then(data => { setEntradas(data) })
      .finally(() => setLoading(false))
  }

  return (
    <div>
      <Container className="central-div">
        <Row>
          <Col>
            <h1>Entradas vendidas</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>Fecha desde</h3>
            <DatePicker selected={startDate}
              onChangeRaw={e => e.preventDefault()}
              onChange={date => setStartDate(date)}
              className="date-picker-input"
              dateFormat='yyyy-MM-dd'
            />
          </Col>
          <Col>
            <h3>Fecha hasta</h3>
            <DatePicker selected={endDate}
              onChangeRaw={e => e.preventDefault()}
              onChange={date => setEndDate(date)}
              className="date-picker-input"
              dateFormat='yyyy-MM-dd' />
          </Col>
          <Col>
            {hasRole([ADMIN_ROLE]) &&
              <div>
                <h3>Usuario</h3>
                <input type="text" onKeyPress={checkKeyPress}
                  onChange={e => setUsuario(e.target.value)} >
                </input></div>}

            <Button onClick={() => {
              buscarEntradas()
            }}>
              <FiRefreshCcw />
            </Button>

          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Fecha</th>
                  <th>DNI</th>
                  <th>Nombre</th>
                  <th>Apellido</th>
                  <th>Dirección</th>
                  <th>Telefono</th>
                  <th>Total</th>
                  <th>Estado</th>
                  <th>Medio pago</th>
                  <th>Cerrada</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {entradas.map(entrada =>
                  <tr key={entrada.id}>
                    <td>{entrada.numero}</td>
                    <td>{entrada.fecha.replace('T', ' ')}</td>
                    <td>{entrada.dni}</td>
                    <td>{entrada.nombre}</td>
                    <td>{entrada.apellido}</td>
                    <td>{entrada.direccion}</td>
                    <td>{entrada.telefono}</td>
                    <td>$ {entrada.montoTotal.toFixed(2)}</td>
                    <td>{entrada.estado}</td>
                    <td>{entrada.medioPago}</td>
                    <td>{entrada.idCierreCaja ? "SI" : "NO"}</td>
                    <td>
                      <span style={{ cursor: 'pointer' }}  >
                        <BsFillPrinterFill onClick={() => {
                          reimprimirEntrada(entrada.id)
                        }} />
                      </span>
                      {!entrada.idCierreCaja && entrada.estado == 'ACTIVO' && <span style={{ cursor: 'pointer', marginLeft: '20px' }}  >
                        <ImCancelCircle onClick={() => {
                          setShowModal(true);
                          setEntradaSeleccionada(entrada);
                        }} />
                      </span>}
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>


      <Modal show={showModal} onHide={() => setShowModal(false)} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmación</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'center' }} ><h4>¿Desea anular esta entrada?</h4></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={() => {
            anularEntrada(entradaSeleccionada.id)
          }}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  )

}

export default EntradasList