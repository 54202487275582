import React from 'react';
import Authentication from '../services/authentication-service'

export const UserContext = React.createContext(null);

export const UserProvider = (props) => {
  const [user, setUser] = React.useState(null)

  //Once, for the initial state on the page load
  React.useEffect(() => {
    Authentication.getSession().then(user => {
      setUser(user)
    }).catch(error => {
      setUser(null)
    })
  }, [])

  function hasRole(requiredRoles) {
    for (let index = 0; index < requiredRoles.length; index++) {
      const requiredRole = requiredRoles[index];
      if (user &&
        user.authorities.length > 0 &&
        user.authorities.includes(requiredRole)) {
        return true;
      }
    }
    return false;
  }

  return (
    <UserContext.Provider value={{ user, setUser, hasRole }}>
      {props.children}
    </UserContext.Provider>
  )
}