import React from 'react';

import LoadingOverlay from 'react-loading-overlay';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

export const UIContext = React.createContext(null);

export const UIProvider = (props) => {

  const [loading, setLoading] = React.useState(false)

  const iframeRef = React.useRef(null);

  const printDataUrl = dataUrl => {
    iframeRef.current.src = dataUrl;
  }

  const showMessage = (message, type) => {
    if (type === 'success') {
      toast.success(message)
    } else if (type === 'error') {
      toast.error(message)
    } else {
      toast(message)
    }
  }

  return (
    <UIContext.Provider value={{ showMessage, printDataUrl, setLoading }}>
      <LoadingOverlay active={loading} spinner text='Cargando...' fadeSpeed={100}>
        {props.children}
      </LoadingOverlay>
      <ToastContainer newestOnTop />
      <iframe name="entradaReporteFrame"
        style={{ width: '1px', "height": "1px", "position": "absolute", "left": "-9999px" }}
        title="title"
        ref={iframeRef}
        onLoad={() => {
          if (iframeRef.current.src) {
            window.frames['entradaReporteFrame'].print();
          }
        }}
      >
      </iframe>
    </UIContext.Provider>
  )
}