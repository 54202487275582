import React from "react";

import '../../../../App.css'

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import NumericInput from 'react-numeric-input';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ButtonGroup from 'react-bootstrap/ButtonGroup'

import LocalidadSelect from '../../../shared/localidad-select';
import PersonaService from '../../../../services/persona-service'
import EntradasService from '../../../../services/entradas-service'
import PreciosService from '../../../../services/precios-service'
import { UIContext } from '../../../../contexts/uiContext'

const emptyForm = {
  dni: '',
  nombre: '',
  apellido: '',
  localidad: 'Seguí (Entre Ríos)',
  direccion: '',
  telefono: '',
  menoresLocales1: 0,
  menoresLocales2: 0,
  adultosLocales: 0,
  jubiladosLocales: 0,
  grupoFamiliarLocales: 0,
  carpasLocales: 0,

  menoresGenerales1: 0,
  menoresGenerales2: 0,
  adultosGenerales: 0,
  jubiladosGenerales: 0,
  grupoFamiliarGenerales: 0,
  carpasGenerales: 0,
  medioPago: 'EFECTIVO'
}

let precios = {}

const defaultLocalidad = { value: 'Seguí (Entre Ríos)', label: 'Seguí (Entre Ríos)' }

const radios = [
  { name: 'Efectivo', value: 'EFECTIVO', clickeable: true },
  { name: 'Tarjeta', value: 'TARJETA', clickeable: true },
  { name: 'Abono', value: 'ABONO', clickeable: false },
  { name: 'Exento', value: 'EXENTO', clickeable: false },
];

function Entradas() {

  const [form, setForm] = React.useState(emptyForm)
  const [amount, setAmount] = React.useState(0)
  const [lastDniSearched, setLastDniSearched] = React.useState(0)
  const [ingresoVario, setIngresoVario] = React.useState({})
  const [exento, setExento] = React.useState({})
  const [localidad, setLocalidad] = React.useState(defaultLocalidad)
  const [validated, setValidated] = React.useState(false);

  const [showModal, setShowModal] = React.useState(false)

  const { showMessage, printDataUrl, setLoading } = React.useContext(UIContext)

  const dniInputRef = React.useRef(null);
  const formRef = React.useRef(null);

  React.useEffect(() => {
    if (ingresoVario.numero) {
      handleFormValue('medioPago', 'ABONO')
    }
  }, [ingresoVario.numero]);

  React.useEffect(() => {
    if (exento.id) {
      handleFormValue('medioPago', 'EXENTO')
    }
  }, [exento.id])

  React.useEffect(() => {
    PreciosService.getPreciosVigente()
      .then(data => precios = data)
      .catch(() => showMessage('Ocurrió un error al recuperar los precios vigentes', 'error'))

    dniInputRef.current.focus();
  }, [])

  const imprimirEntrada = entrada => {
    EntradasService.getReporteEntrada(entrada.id).then(dataUrl => {
      printDataUrl(dataUrl);
    }).catch(error => {
      showMessage(error, 'error');
    })
  }

  const handleFormValue = (name, value) => {
    if (name === 'medioPago') {

      if (value !== 'ABONO') {
        setIngresoVario({})
      }

      if (value !== 'EXENTO') {
        setExento({})
      }
    }

    const newForm = {
      ...form,
      [name]: value
    }
    const newAmount = calculateAmount(newForm)
    setForm(newForm)
    setAmount(newAmount)
  }

  const calculateAmount = (form) => {
    const amount =
      form.menoresLocales1 * precios.menoresLocales1 +
      form.menoresLocales2 * precios.menoresLocales2 +
      form.adultosLocales * precios.adultosLocales +
      form.jubiladosLocales * precios.jubiladosLocales +
      form.grupoFamiliarLocales * precios.grupoFamiliarLocales +
      form.carpasLocales * precios.carpasLocales +
      form.menoresGenerales1 * precios.menoresGenerales1 +
      form.menoresGenerales2 * precios.menoresGenerales2 +
      form.adultosGenerales * precios.adultosGenerales +
      form.jubiladosGenerales * precios.jubiladosGenerales +
      form.grupoFamiliarGenerales * precios.grupoFamiliarGenerales +
      form.carpasGenerales * precios.carpasGenerales

    return amount
  }

  const submit = () => {

    const body = Object.assign({}, form)
    body.montoTotal = amount
    body.localidad = localidad.value
    body.idPrecio = precios.id

    if (ingresoVario.numero) {
      body.idIngresoVario = ingresoVario.id;
    }

    setLoading(true)
    EntradasService.createEntrada(body).then((data) => {
      setShowModal(false)
      clearForm()
      imprimirEntrada(data)
    }).finally(() => setLoading(false))
  }

  const clearForm = () => {
    setForm(emptyForm)
    setAmount(0)
    setLastDniSearched(0)
    setIngresoVario({})
    setExento({})
    setLocalidad(defaultLocalidad)
    dniInputRef.current.focus();
    setValidated(false)
  }

  function buscarIngresoVario(respuesta) {
    if (respuesta.ingresoVario) {
      setIngresoVario(respuesta.ingresoVario)
    } else {
      setIngresoVario({})
    }
  }

  function buscarExento(respuesta) {
    if (respuesta.exento) {
      setExento(respuesta.exento)
    } else {
      setExento({})
    }
  }

  function confirmar() {
    const form = formRef.current
    if (form.checkValidity()) {
      setShowModal(true)
    }
    setValidated(true)
  }

  function buscarPersonaByDni() {
    if (form.dni &&
      form.dni.length >= 7 &&
      form.dni !== lastDniSearched) {
      setLoading(true)
      PersonaService.getPersonaByDNI(form.dni).then(respuesta => {
        if (respuesta.persona) {
          const newForm = {
            ...form,
            nombre: respuesta.persona.nombre,
            apellido: respuesta.persona.apellido,
            localidad: respuesta.persona.localidad,
            direccion: respuesta.persona.direccion,
            telefono: respuesta.persona.telefono
          }
          setForm(newForm)
          buscarIngresoVario(respuesta)
          buscarExento(respuesta)
        }
      }).finally(() => setLoading(false))
      setLastDniSearched(form.dni)
    }
  }

  function checkKeyPress(event) {
    if (event.key === 'Enter') {
      buscarPersonaByDni();
    }
  }

  return (
    <div>
      <Container>
        <Row>
          <Col md={8}>
            <Form noValidate validated={validated}
              className="central-div"
              autoComplete="off"
              style={{ minHeight: '615px' }}
              ref={formRef}
            >
              <Row className="row-mb-5">
                <Col md={4}>
                  <Card>
                    <Card.Body>
                      <Card.Title>Datos del Titular</Card.Title>

                      <Form.Group controlId="validationCustom01">
                        <Form.Label>DNI</Form.Label>
                        <Form.Control type="text" name="dni"
                          disabled={ingresoVario.numero || exento.id}
                          required
                          ref={dniInputRef}
                          value={form.dni}
                          onChange={e => handleFormValue('dni', e.target.value)}
                          onKeyPress={checkKeyPress}
                          onBlur={buscarPersonaByDni} />
                      </Form.Group>

                      <Form.Group controlId="NOMBRE">
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control type="text" name="nombre"
                          disabled={ingresoVario.numero || exento.id}
                          required
                          value={form.nombre} onChange={e => handleFormValue('nombre', e.target.value)} />
                      </Form.Group>

                      <Form.Group controlId="APELLIDO">
                        <Form.Label>Apellido</Form.Label>
                        <Form.Control type="text" name="apellido"
                          disabled={ingresoVario.numero || exento.id}
                          required
                          value={form.apellido} onChange={e => handleFormValue('apellido', e.target.value)} />
                      </Form.Group>

                      <Form.Group controlId="LOCALIDAD">
                        <Form.Label>Localidad</Form.Label>
                        <LocalidadSelect value={localidad} onSelect={(localidad) => setLocalidad(localidad)} />
                      </Form.Group>

                      <Form.Group controlId="DIRECCION">
                        <Form.Label>Dirección</Form.Label>
                        <Form.Control type="text" name="direccion"
                          required
                          value={form.direccion} onChange={e => handleFormValue('direccion', e.target.value)} />
                      </Form.Group>

                      <Form.Group controlId="TELEFONO">
                        <Form.Label>Teléfono</Form.Label>
                        <Form.Control type="text" name="telefono"
                          required
                          value={form.telefono} onChange={e => handleFormValue('telefono', e.target.value)} />
                      </Form.Group>

                    </Card.Body>
                  </Card>
                </Col>
                <Col md={4}>
                  <Card>
                    <Card.Body>
                      <Card.Title>Visitas Locales</Card.Title>
                      <Form.Group controlId="MenoresLocales" validationState={null} >
                        <Form.Label>4 a 11 años</Form.Label>
                        <NumericInput mobile className="form-control" value={form.menoresLocales1} min={0}
                          onChange={value => handleFormValue('menoresLocales1', value)} />
                      </Form.Group>
                      <Form.Group controlId="MenoresLocales">
                        <Form.Label>12 a 18 años</Form.Label>
                        <NumericInput mobile className="form-control" value={form.menoresLocales2} min={0}
                          onChange={value => handleFormValue('menoresLocales2', value)} />
                      </Form.Group>
                      <Form.Group controlId="AdultosLocales">
                        <Form.Label>Adultos</Form.Label>
                        <NumericInput mobile className="form-control" value={form.adultosLocales} min={0}
                          onChange={value => handleFormValue('adultosLocales', value)} />
                      </Form.Group>
                      <Form.Group controlId="JubiladosLocales">
                        <Form.Label>Jubilados</Form.Label>
                        <NumericInput mobile className="form-control" value={form.jubiladosLocales} min={0}
                          onChange={value => handleFormValue('jubiladosLocales', value)} />
                      </Form.Group>
                      <Form.Group controlId="GroupoFamiliarLocales">
                        <Form.Label>Grupo Familiar</Form.Label>
                        <NumericInput mobile className="form-control" value={form.grupoFamiliarLocales} min={0}
                          onChange={value => handleFormValue('grupoFamiliarLocales', value)} />
                      </Form.Group>
                      <Form.Group controlId="CarpasLocales">
                        <Form.Label>Carpas</Form.Label>
                        <NumericInput mobile className="form-control" value={form.carpasLocales} min={0}
                          onChange={value => handleFormValue('carpasLocales', value)} />
                      </Form.Group>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={4}>
                  <Card>
                    <Card.Body>
                      <Card.Title>Visitas Generales</Card.Title>
                      <Form.Group controlId="MenoresGenerales">
                        <Form.Label>4 a 11 años</Form.Label>
                        <NumericInput mobile className="form-control" value={form.menoresGenerales1} min={0}
                          onChange={value => handleFormValue('menoresGenerales1', value)} />
                      </Form.Group>
                      <Form.Group controlId="MenoresGenerales">
                        <Form.Label>12 a 18 años</Form.Label>
                        <NumericInput mobile className="form-control" value={form.menoresGenerales2} min={0}
                          onChange={value => handleFormValue('menoresGenerales2', value)} />
                      </Form.Group>
                      <Form.Group controlId="AdultosGenerales">
                        <Form.Label>Adultos</Form.Label>
                        <NumericInput mobile className="form-control" value={form.adultosGenerales} min={0}
                          onChange={value => handleFormValue('adultosGenerales', value)} />
                      </Form.Group>
                      <Form.Group controlId="JubiladosGenerales">
                        <Form.Label>Jubilados</Form.Label>
                        <NumericInput mobile className="form-control" value={form.jubiladosGenerales} min={0}
                          onChange={value => handleFormValue('jubiladosGenerales', value)} />
                      </Form.Group>
                      <Form.Group controlId="GroupoFamiliarGenerales">
                        <Form.Label>Grupo Familiar</Form.Label>
                        <NumericInput mobile className="form-control" value={form.grupoFamiliarGenerales} min={0}
                          onChange={value => handleFormValue('grupoFamiliarGenerales', value)} />
                      </Form.Group>
                      <Form.Group controlId="CarpasGenerales">
                        <Form.Label>Carpas</Form.Label>
                        <NumericInput mobile className="form-control" value={form.carpasGenerales} min={0}
                          onChange={value => handleFormValue('carpasGenerales', value)} />
                      </Form.Group>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Form>
          </Col>
          <Col md={4}>
            <div className="central-div" style={{ position: 'relative', minHeight: '615px' }}>
              <div className="center">
                <h1>Total</h1>
                <h1>$ {amount.toFixed(2)}</h1>
                <ButtonGroup>
                  {radios.map((radio, idx) => (
                    <ToggleButton
                      key={idx}
                      id={`radio-${idx}`}
                      type="radio"
                      name="radio"
                      value={radio.value}
                      variant="outline-primary"
                      checked={form.medioPago === radio.value}
                      onChange={(e) => handleFormValue('medioPago', e.currentTarget.value)}
                      disabled={!radio.clickeable}
                    >
                      {radio.name}
                    </ToggleButton>
                  ))}
                </ButtonGroup>
                {ingresoVario.numero && <div className="abono-div">
                  <h4>Datos del abono:</h4>
                  Número: {ingresoVario.numero}<br />
                  Fecha de pago: {ingresoVario.fechaPago.substring(0, 10)}<br />
                  Fecha vencimiento: {ingresoVario.fechaVencimiento.substring(0, 10)}<br />
                  Pagado por: {ingresoVario.pagadoPor}<br />
                </div>}

                {exento.id && <div className="abono-div">
                  <h4>Datos del exento:</h4>
                  DNI: {exento.dni}<br />
                  Nombre: {exento.nombre}<br />
                  Apellido: {exento.apellido}<br />
                </div>}
              </div>
            </div>

          </Col>
        </Row>
        <Row className="row-mb-5">
          <Col>
            <div className="central-div">
              <Button size="lg" variant="danger" className="button-mr-5" onClick={clearForm}>
                Limpiar
              </Button>
              <Button size="lg"
                type="submit"
                onClick={() => confirmar()}
                disabled={form.medioPago !== 'ABONO' && amount <= 0 && form.medioPago !== 'EXENTO'}
              >
                Confirmar
              </Button>
            </div>
          </Col>
        </Row>
      </Container>

      <Modal show={showModal} onHide={() => setShowModal(false)} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmación</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'center' }} ><h4>¿Generar entrada?</h4></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={submit}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>

  )
}

export default Entradas
