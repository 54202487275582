import React from 'react';
import Login from '../../shared/login';


import { UserContext } from '../../../contexts/userContext'

function Main() {
  const { user } = React.useContext(UserContext)


  return user ? <div>



  </div> : <Login />
}

export default Main