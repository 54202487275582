import React from 'react'

import PreciosService from '../../../../services/precios-service'

import '../../../../App.css'

import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { UserContext } from '../../../../contexts/userContext'

import { Link } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";

function PreciosList() {

  const [precios, setPrecios] = React.useState([])
  const { user } = React.useContext(UserContext)


  React.useEffect(() => {
    console.log(user);
    PreciosService.getPrecios().then(data => { setPrecios(data) })
  }, [])

  return (

    <Container className="central-div">
      <Row>
        <Col>
          <h1>Precios</h1>
          <Link to={"/precios/new"} style={{ float: 'right' }}>
            <Button >
              + Agregar precio
            </Button>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Adlts grales</th>
                <th>Adlts locales</th>
                <th>Carpas grales</th>
                <th>Carpas locales</th>
                <th>Grupo familiar grales</th>
                <th>Grupo familiar local</th>
                <th>Jubilado grales</th>
                <th>Jubilados locales</th>
                <th>Menores grales1</th>
                <th>Menores grales2</th>
                <th>Menores locales1</th>
                <th>Menores locales2</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {precios.map((precio, index) =>
                <tr key={index}>
                  <td>{precio.fechaFin.toISOString().substring(0, 10)}</td>
                  <td>{precio.adultosGenerales}</td>
                  <td>{precio.adultosLocales}</td>
                  <td>{precio.carpasGenerales}</td>
                  <td>{precio.carpasLocales}</td>
                  <td>{precio.grupoFamiliarGenerales}</td>
                  <td>{precio.grupoFamiliarLocales}</td>
                  <td>{precio.jubiladosGenerales}</td>
                  <td>{precio.jubiladosLocales}</td>
                  <td>{precio.menoresGenerales1}</td>
                  <td>{precio.menoresGenerales2}</td>
                  <td>{precio.menoresLocales1}</td>
                  <td>{precio.menoresLocales2}</td>
                  <Link to={"/precios/" + precio.id}>
                  <td>
                    <Button>Modificar</Button>
                  </td>
                  </Link>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>





  )

}

export default PreciosList