import axios from 'axios';



function fromServer(precios) {
  if (precios.fechaFin) {
    precios.fechaFin = new Date(precios.fechaFin)
  }
  return precios
}

const PreciosService = {
  getPreciosVigente: () => {
    return axios.get('/api/polideportivo/precios/vigente').then(resp => resp.data)
  },
  getPrecios: () => {
    return axios.get('/api/polideportivo/precios').then(resp => resp.data.map(fromServer))
  },
  createPrecio: precio => {
    return axios.post('/api/polideportivo/precios', precio).then(resp => resp.data)
  },
  getPrecio: id => {
    return axios.get('/api/polideportivo/precios/' + id).then(resp => fromServer(resp.data))
  },
  updatePrecio: precio => {
    return axios.put('/api/polideportivo/precios', precio).then(resp => resp.data)
  }
}


export default PreciosService