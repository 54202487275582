import React from "react";

import '../../../../App.css'

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import { Link, useParams, useHistory } from "react-router-dom";

import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";

import PreciosService from '../../../../services/precios-service'
import { UIContext } from '../../../../contexts/uiContext'


const emptyForm = {
    fechaFin: new Date()
}


function PreciosNew() {

    const history = useHistory()
    const [form, setForm] = React.useState(emptyForm)
    const [showModal, setShowModal] = React.useState(false)
    const { showMessage } = React.useContext(UIContext)
    const { id } = useParams()

    React.useEffect(() => {
        if (id) {
            PreciosService.getPrecio(id).then(precio => {
                setForm(precio)
            });
        }
    }, [])

    const handleFormValue = (name, value) => {
        const newForm = {
            ...form,
            [name]: value
        }
        setForm(newForm)
    }

    const handleSuccess = message => {
        setShowModal(false)
        showMessage(message, 'success')
        history.push('/precios')
    }

    const submit = () => {
        const body = Object.assign({}, form)
        if (id) {
            PreciosService.updatePrecio(body).then(precio => {
                handleSuccess('Precios actualizados')
            })
        } else {
            PreciosService.createPrecio(body).then((data) => {
                handleSuccess('Nuevo precio creado')
            })
        }
    }


    return (
        <div>
            <Container className="central-div">
                <Card>
                    <h1>Agregar o modificar precios</h1>
                    <Card.Body>
                        <Form className="central-div" autoComplete="off" style={{ minHeight: '615px' }}>
                            <Row>
                                <Col>
                                    <h3>Precios vigentes hasta la fecha:</h3>
                                    <DatePicker 
                                    className="date-picker-input"
                                    onChangeRaw={e => e.preventDefault()}
                                    selected={form.fechaFin} 
                                    onChange={date => handleFormValue('fechaFin', date)}
                                    dateFormat='yyyy-MM-dd' />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <h1>Locales</h1>
                                    <Form.Group controlId="MenoresLocales">
                                        <Form.Label>4 a 11 años</Form.Label>
                                        <Form.Control type="text" name="menoresLocales1" value={form.menoresLocales1} onChange={e => handleFormValue('menoresLocales1', e.target.value)} />
                                    </Form.Group>
                                    <Form.Group controlId="MenoresLocales">
                                        <Form.Label>12 a 18 años</Form.Label>
                                        <Form.Control type="text" name="menoresLocales2" value={form.menoresLocales2} onChange={e => handleFormValue('menoresLocales2', e.target.value)} />
                                    </Form.Group>
                                    <Form.Group controlId="AdultosLocales">
                                        <Form.Label>Adultos</Form.Label>
                                        <Form.Control type="text" name="adultosLocales" value={form.adultosLocales} onChange={e => handleFormValue('adultosLocales', e.target.value)} />
                                    </Form.Group>
                                    <Form.Group controlId="JubiladosLocales">
                                        <Form.Label>Jubilados</Form.Label>
                                        <Form.Control type="text" name="jubiladosLocales" value={form.jubiladosLocales} onChange={e => handleFormValue('jubiladosLocales', e.target.value)} />
                                    </Form.Group>
                                    <Form.Group controlId="GrupoFamiliarLocales">
                                        <Form.Label>Grupo Familiar</Form.Label>
                                        <Form.Control type="text" name="grupoFamiliarLocales" value={form.grupoFamiliarLocales} onChange={e => handleFormValue('grupoFamiliarLocales', e.target.value)} />
                                    </Form.Group>
                                    <Form.Group controlId="CarpasLocales">
                                        <Form.Label>Carpas</Form.Label>
                                        <Form.Control type="text" name="carpasLocales" value={form.carpasLocales} onChange={e => handleFormValue('carpasLocales', e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <h1>Generales</h1>
                                    <Form.Group controlId="MenoresGenerales">
                                        <Form.Label>4 a 11 años</Form.Label>
                                        <Form.Control type="text" name="menoresGenerales1" value={form.menoresGenerales1} onChange={e => handleFormValue('menoresGenerales1', e.target.value)} />
                                    </Form.Group>
                                    <Form.Group controlId="MenoresGenerales">
                                        <Form.Label>12 a 18 años</Form.Label>
                                        <Form.Control type="text" name="menoresGenerales2" value={form.menoresGenerales2} onChange={e => handleFormValue('menoresGenerales2', e.target.value)} />
                                    </Form.Group>
                                    <Form.Group controlId="AdultosGenerales">
                                        <Form.Label>Adultos</Form.Label>
                                        <Form.Control type="text" name="adultosGenerales" value={form.adultosGenerales} onChange={e => handleFormValue('adultosGenerales', e.target.value)} />
                                    </Form.Group>
                                    <Form.Group controlId="JubiladosGenerales">
                                        <Form.Label>Jubilados</Form.Label>
                                        <Form.Control type="text" name="jubiladosGenerales" value={form.jubiladosGenerales} onChange={e => handleFormValue('jubiladosGenerales', e.target.value)} />
                                    </Form.Group>
                                    <Form.Group controlId="GrupoFamiliarGenerales">
                                        <Form.Label>Grupo Familiar</Form.Label>
                                        <Form.Control type="text" name="grupoFamiliarGenerales" value={form.grupoFamiliarGenerales} onChange={e => handleFormValue('grupoFamiliarGenerales', e.target.value)} />
                                    </Form.Group>
                                    <Form.Group controlId="CarpasGenerales">
                                        <Form.Label>Carpas</Form.Label>
                                        <Form.Control type="text" name="carpasGenerales" value={form.carpasGenerales} onChange={e => handleFormValue('carpasGenerales', e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                        <Row className="row-mb-5">
                            <Col>
                                <div className="central-div">
                                    <Link to={"/precios"}>
                                        <Button size="lg" variant="danger" className="button-mr-5">
                                            Cancelar
                                        </Button>
                                    </Link>
                                    <Button size="lg" type="submit" onClick={() => setShowModal(true)} >
                                        Confirmar
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Container>

            <Modal show={showModal} onHide={() => setShowModal(false)} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmar</Modal.Title>
                </Modal.Header>
                <Modal.Body>¿Seguro que quieres actualizar los precios?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cerrar
                    </Button>
                    <Button variant="primary" onClick={submit}>
                        Guardar precios
                    </Button>
                </Modal.Footer>
            </Modal>
        </div >
    )
}
export default PreciosNew
